import { capitalize, unescapeHTML } from '@utilities/helpers/core.helpers'

import type {
  AdditionalServiceItemProps,
  BuildingBlockPostType,
  CartItemProps,
  RoundtripPostType,
} from '@typings'
import { isTypeAdditionalService } from '@typings'

export const makeCartItem = (
  destination: string,
  block: RoundtripPostType | BuildingBlockPostType | AdditionalServiceItemProps,
  parent?: string
) => {
  let type: CartItemProps['type']
  let id: CartItemProps['id']
  let cartId: CartItemProps['cartId']
  let idType: CartItemProps['idType']
  let gid: CartItemProps['gid']
  let title: CartItemProps['title']
  let image: CartItemProps['image']
  let link: CartItemProps['link']
  let keyFacts: CartItemProps['keyFacts']
  let slug: CartItemProps['slug']
  let region: CartItemProps['region']
  let teaser: CartItemProps['teaser']

  if (!block) return null

  if (isTypeAdditionalService(block)) {
    type =
      block.category !== 'modified_travel_extending'
        ? 'variant'
        : 'additional_service'
    id = null
    cartId = block.gid
    idType = 'gid'
    gid = block.gid
    title = block.headline
    teaser = null
    image = block.slideshow[0] || null
    link = null
    slug = null
    keyFacts = [
      {
        title: 'TRIP_DURATION',
        text: block.services,
        remove_from_detail: false,
        teaser_checkbox: false,
      },
      {
        title: 'TRIP_PRICE',
        text: block.traveling_price,
        remove_from_detail: false,
        teaser_checkbox: false,
      },
    ]
  } else {
    const bbOrRt = block.type === 'reise-baustein' ? 'bb' : 'rt'
    const facts =
      block.acf?.[`${bbOrRt}_keyfacts_repeater`]?.[0]
        ?.output_type_table_repeater

    id = block.id
    type = block.type
    cartId = block.id?.toString()
    idType = 'id'
    gid = block.acf[`${bbOrRt}_gid`]
    title = block.title?.rendered
    image = block.featured_image
    link = {
      url: block.link,
      title: block.title?.rendered,
    }
    keyFacts = facts
    slug = block.slug
    region = block.acf.category_name
    teaser = block.acf?.[`${bbOrRt}_teaser_text`]
  }

  return {
    type,
    id,
    cartId,
    idType,
    gid,
    title: unescapeHTML(title),
    image,
    link,
    keyFacts,
    parent,
    slug,
    region: unescapeHTML(region),
    country: capitalize(destination),
    teaser,
  } as CartItemProps
}
